import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, User, getAuth, onAuthStateChanged, FacebookAuthProvider,
    signInWithPopup, signOut } from 'firebase/auth';
import { AppPagePath, AppStorageKey } from 'src/app/models/enums/app-constant';
import { environment } from 'src/environments/environment';
import { IUser } from '../shared/interfaces';
import { AccountService } from './account-service';
import { ApiService } from './api.service';
import { LocalStorageService } from './local-storage-service';
import { UserService } from './user.service';
import { UtilitiesService } from './utilities.service';
import { ExceptionService } from './exception.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    firebase: any;
    public loading = false;
    public currentUser: IUser = null; // user from DB;

    constructor(
        private userService: UserService,
        private localStorageService: LocalStorageService,
        private utilitiesService: UtilitiesService,
        private accountService: AccountService,
        private exceptionService: ExceptionService,
        private apiService: ApiService) {
        this.firebase = initializeApp(environment.firebase);
    }

    public async refreshToken() {
        // if (this.utilitiesService.isWeb) {
        if (this.utilitiesService.currentPlatform !== 'cordova') {
            console.log('LoginService: refreshToken: this.utilitiesService.currentPlatform is not cordova, return');
            return;
        }
        return new Promise((resolve, reject) => {
            try {
                this.loading = true;
                const auth = getAuth(this.firebase);
                onAuthStateChanged(auth, async (user: User | null) => {
                    if (user) {
                        const idToken = await user.getIdToken(true);
                        this.apiService.token = idToken;
                        localStorage.setItem('token', idToken);
                        this.accountService.userProvider = user;
                        const email = user.email;
                        if (email) {
                            const users = await this.userService.getUsers({
                                where: {
                                    email,
                                }
                            }).toPromise();
                            if (users && users.length > 0) {
                                const firstUser = users[0];
                                this.currentUser = firstUser;
                                firstUser.token = idToken;
                                this.apiService.put({
                                    resource: 'users/' + firstUser.id,
                                    payload: {
                                        token: idToken,
                                        lastLogin: new Date(),
                                        loginType: 'google.com',
                                        loginFrom: 'football',
                                    },
                                }).subscribe(() => {
                                }, (errAPI) =>
                                    this.exceptionService.catchBadResponse(errAPI)
                                );
                            } else {
                                const addedUser: IUser = {
                                    email: user.email,
                                    name: user.displayName,
                                    avatar: user.photoURL,
                                    phone: user.phoneNumber,
                                    token: idToken,
                                    loginType: 'google.com',
                                    loginFrom: 'football',
                                    lastLogin: new Date(),
                                };
                                this.currentUser = await this.userService.addUser(addedUser).toPromise();
                            }
                        }
                        await this.localStorageService.set(AppStorageKey.accessToken, idToken);
                    } else {
                        await this.logout();
                    }
                    resolve(user);
                });
            } catch (exRefreshToken) {
                console.error('LoginService: refreshToken: exRefreshToken: ', exRefreshToken);
                reject('LoginService: refreshToken error');
            } finally {
                this.loading = false;
            }
        });
    }

    async logout() {
        const auth = getAuth(this.firebase);
        await auth.signOut();
        signOut(auth).then(() => {
            this.apiService.token = null;
            localStorage.setItem('token', null);
            this.currentUser = null;
            this.accountService.logout().then(() => {});
        }).catch((e) => {
            console.log('==== LoginService: error: ', e);
        });
    }

    async loginViaGoogle() {
        return new Promise((resolve, reject) => {
            try {
                const provider = new GoogleAuthProvider();
                const auth = getAuth(this.firebase);
                auth.languageCode = 'vi';
                // can't run on Browser ?
                // signInWithRedirect(auth, provider).then((resSignIn) => {
                //     alert('==== resSignIn :' + resSignIn);
                //     resolve(null);
                // });
                signInWithPopup(auth, provider)
                    .then((result) => {
                        const credential = GoogleAuthProvider.credentialFromResult(result);
                        const accessToken = credential.accessToken;
                        const user = result.user;
                        if (user) {
                            this.localStorageService.set(AppStorageKey.accessToken, accessToken).then(() => {
                                this.apiService.token = accessToken;
                                localStorage.setItem('token', accessToken);
                                this.accountService.login({ name: user.displayName, email: user.email,
                   avatar: user.photoURL }).then(() => {
                                    // this.router.navigateByUrl(AppPagePath.home);
                                });
                            });
                        }
                        resolve(user);
                    }).catch((error) => {
                        // Handle Errors here.
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.log('==== errorMessage:', errorMessage);
                        // The email of the user's account used.
                        const email = error.customData.email;
                        // The AuthCredential type that was used.
                        const credential = GoogleAuthProvider.credentialFromError(error);
                        // ...
                        reject(error);
                    }).finally(() => {
                    });
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    }

    async loginViaFacebook() {
        return new Promise((resolve, reject) => {
            try {
                const provider = new FacebookAuthProvider();
                const auth = getAuth(this.firebase);
                auth.languageCode = 'vi';
                // signInWithCustomToken(auth,
                //     .then((result) => {
                //         console.log('==== result :', result);
                //     });
                signInWithPopup(auth, provider)
                    .then((result) => {
                        // The signed-in user info.
                        console.log('==== result :', result);
                        const user = result.user;

                        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                        const credential = FacebookAuthProvider.credentialFromResult(result);
                        const accessToken = credential.accessToken;

                        // IdP data available using getAdditionalUserInfo(result)
                        // ...
                    })
                    .catch((error) => {
                        // Handle Errors here.
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        // The email of the user's account used.
                        const email = error.customData.email;
                        // The AuthCredential type that was used.
                        const credential = FacebookAuthProvider.credentialFromError(error);

                        // ...
                    });
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    }
}
