import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { ToastService } from './toast.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Injectable()
export class ExceptionService {
  constructor(
    private router: Router,
    private toastService: ToastService) { }

  public catchBadResponse: (errorResponse: any) => Observable<any> = (errorResponse: any) => {
    const res = errorResponse;
    let err = null;

    const msgHdr = 'ai.api.service.ts: catchBadResponse: ';

    console.log(msgHdr + ', res = ', res);
    if (res && res.json) {
      err = res.json();
    }
    console.log(msgHdr + ', err = ', err);

    if (res && res.status === 401) {
      if (window.confirm('Phiên đăng nhập hết hiệu lực. Click Ok để tải lại trang.')) {
        window.location.reload();
      };
      return of(false, 401);
    }

    if (res && res.status === 403)  {
      if (res.url.indexOf('yelp') >= 0) {
        return of(false);
      }
      const message: any = res;
      this.toastService.activate(`${message.error.message}`, 'error');
      console.log(msgHdr + 'exception go to login');
      this.router.navigate(['/login']);
      return of(false);
    }

    if (res && res.error && res.error.message) {
      this.toastService.activate(res.error.message, 'error');
      return of(false);
    }

    if (err && err.message) {
      this.toastService.activate(`${err.message}`, 'error');
      console.error(`${err.message}`, 'error');
    } else if (err && err.code && err.code === 'NotFoundError') {
      this.toastService.activate('Your request failed, because we could not find a related item in the system', 'error');
      console.error('Your request failed, because we could not find a related item in the system', 'error');
    } else {
      console.error(msgHdr + 'toasting on error');
      const emsg = err ?
        (err.error ? err.error :
        (err.message ? err.message : JSON.stringify(err))) :
        (res.statusText || 'unknown error');
      this.toastService.activate(`Error - Bad Response - ${emsg}`, 'error');
      console.error(`Error - Bad Response - ${emsg}`, 'error');
    }
    return of(false);
  };
}
